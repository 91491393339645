import Link from 'next/link'
import { startTransition, useState } from 'react'

import CTAArrow from './icons/CTAArrow'
import StaggerText from './StaggerText'

interface ButtonProps {
  children: string
  onClick?: () => void
  href?: string
  asLink?: boolean
  className?: string
}

const ButtonContent = ({
  isHovered,
  children,
}: {
  isHovered: boolean
  children: string
}) => (
  <div className="mono-large text-14 md:text-sm text-white group-hover:text-green transition-color duration-200">
    <StaggerText isHovered={isHovered} text={children} />
  </div>
)

const Button = ({
  children,
  onClick,
  href,
  asLink,
  className,
}: ButtonProps) => {
  const [isHovered, setIsHovered] = useState(false)

  if (asLink) {
    return (
      <Link
        href={href}
        className={`group flex items-center gap-3 ${className}`}
        onClick={onClick}
        onMouseEnter={() => {
          startTransition(() => {
            setIsHovered(true)
          })
        }}
        onMouseLeave={() => {
          startTransition(() => {
            setIsHovered(false)
          })
        }}
      >
        <ButtonContent isHovered={isHovered}>{children}</ButtonContent>
        <CTAArrow className="text-white group-hover:text-green transition-color duration-200" />
      </Link>
    )
  }

  return (
    <button
      className={`group flex items-center gap-3 ${className}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ButtonContent isHovered={isHovered}>{children}</ButtonContent>
      <CTAArrow className="text-white group-hover:text-green transition-color duration-200" />
    </button>
  )
}

export default Button

import Image from 'next/image'
import React from 'react'

type PillButtonProps = {
  label: string
  onClick?: () => void
  isActive: boolean
  className?: string
}

const PillButton = ({
  label,
  onClick,
  isActive,
  className,
}: PillButtonProps) => {
  return (
    <button
      className={`
        group  
        text-grey
        mono-large
        flex flex-row items-center justify-start
        border border-grey-200 rounded-full
        h-10
        z-[100]
        relative
        box-content
        bg-black bg-opacity-50
        transition-border transition-color transition-background-color duration-200
        hover:bg-grey-100 hover:bg-opacity-50
        backdrop-blur-lg
        flex-shrink-0
        ${isActive ? 'is-active text-off-white border-grey-200 bg-grey-100' : ''}
        ${className || ''}
      `}
      onClick={onClick}
    >
      <div
        className="
        h-full
        pr-4
        transform w-0 group-[.is-active]:w-10
        transition-width duration-200 delay-100 group-[.is-active]:delay-100
        will-change-width
      "
      ></div>
      <div
        className="
        w-8 h-8
        absolute left-1
        transform scale-0 group-[.is-active]:scale-100
        transition-transform duration-200 delay-0 group-[.is-active]:delay-100
        bg-black
        rounded-full overflow-hidden
      "
      >
        <Image
          alt={'Icon'}
          src={'/images/pill-button-a.webp'}
          width={32}
          height={32}
          className="object-cover opacity-50"
        />
      </div>
      <div
        className="
        h-full
        pl-1 pr-4
        flex flex-row items-center justify-start
      "
      >
        {label}
      </div>
    </button>
  )
}

export default PillButton

import { useScrollbar } from '@14islands/r3f-scroll-rig'
import { useEffect, useRef } from 'react'

import { HOME_SECTIONS } from '~/components/constants'
import PillButton from '~/components/PillButton'

export default function SectionNavigation({
  activeSection,
  handleSectionClick,
  isVisible,
}: {
  activeSection: string | null
  handleSectionClick: (sectionName: string) => void
  isVisible: boolean
}) {
  const refContainer = useRef<HTMLDivElement[]>([])
  const scroll = useScrollbar()

  useEffect(() => {
    if (activeSection && refContainer.current) {
      const index = HOME_SECTIONS.indexOf(activeSection)
      if (refContainer.current[index]) {
        refContainer.current[index].scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
        })
      }
    }
  }, [activeSection])

  return (
    <div
      className={`
        fixed pt-header md:pt-header-lg flex w-full items-center justify-center z-[100] mt-4 md:mt-8 no-wrap
        overflow-auto
        px-4 md:px-0 pb-4
        transition-all duration-300
        minimal-scrollbar scroll-smooth
        bg-section-nav
        ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}
      `}
    >
      <div className="flex flex-shrink-0 gap-3 snap-start items-start justify-start overflow-auto scale-x-1">
        {HOME_SECTIONS.map((section, index) => (
          <div
            className={`flex-shrink-0 snap-center`}
            key={index}
            ref={(el) => (refContainer.current[index] = el)}
          >
            <PillButton
              onClick={() => handleSectionClick(section)}
              isActive={activeSection === section}
              label={section}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

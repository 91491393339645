import { Loader } from '@react-three/drei'
import type { GetStaticProps, InferGetStaticPropsType } from 'next'
import Head from 'next/head'
import { lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react'

import { PAGE_TITLE_PREFIX } from '~/components/constants'
import Hero from '~/components/home/Hero'
import SectionNavigation from '~/components/home/SectionNavigation'
import Layout from '~/components/Layout'
import { readToken } from '~/lib/sanity.api'
import { getClient } from '~/lib/sanity.client'
import {
  getHomepage,
  getNavigation,
  getPricingPage,
  type Homepage,
  Navigation,
  PricingPage,
} from '~/lib/sanity.queries'
import type { SharedPageProps } from '~/pages/_app'

const OurNodes = lazy(() => import('~/components/home/OurNodes'))
const OurServices = lazy(() => import('~/components/home/OurServices'))
const BasicSection = lazy(() => import('~/components/BasicSection'))
const Deployment = lazy(() => import('~/components/home/Deployment'))
const GPUPower = lazy(() => import('~/components/home/GPUPower'))
const HowItWorks = lazy(() => import('~/components/home/HowItWorks'))
const Pricing = lazy(() => import('~/components/home/Pricing'))

export const getStaticProps: GetStaticProps<
  SharedPageProps & {
    homepage: Homepage
    navigation: Navigation
    pricing: PricingPage
  }
> = async ({ draftMode = false }) => {
  const client = getClient(draftMode ? { token: readToken } : undefined)
  const homepage = await getHomepage(client)
  const navigation = await getNavigation(client)
  const pricing = await getPricingPage(client)

  return {
    props: {
      draftMode,
      token: draftMode ? readToken : '',
      homepage,
      navigation,
      pricing,
    },
  }
}

export default function IndexPage(
  props: InferGetStaticPropsType<typeof getStaticProps>,
) {
  const homepage = props.homepage
  const navigation = props.navigation
  const pricing = props.pricing

  const HeroRef = useRef<HTMLDivElement>(null)
  const FeaturesRef = useRef<HTMLDivElement>(null)
  const OurServicesRef = useRef<HTMLDivElement>(null)
  const HowItWorksRef = useRef<HTMLDivElement>(null)
  const GPUPowerRef = useRef<HTMLDivElement>(null)
  const PricingRef = useRef<HTMLDivElement>(null)
  const FooterRef = useRef<HTMLDivElement>(null)
  const [activeSection, setActiveSection] = useState<string | null>(null)
  const [isSectionNavigationVisible, setIsSectionNavigationVisible] =
    useState(false)
  const sectionRefs = useMemo(
    () => ({
      Hero: HeroRef,
      Features: FeaturesRef,
      'Our Services': OurServicesRef,
      'How it Works': HowItWorksRef,
      'GPU Power': GPUPowerRef,
      Pricing: PricingRef,
      Footer: FooterRef,
    }),
    [FeaturesRef, OurServicesRef, HowItWorksRef, GPUPowerRef, PricingRef],
  )

  const [isScrollingIntoView, setIsScrollingIntoView] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const sectionName = Object.keys(sectionRefs).find(
            (key) => sectionRefs[key].current === entry.target,
          )

          if (entry.isIntersecting) {
            if (sectionName === 'Hero' || sectionName === 'Footer') {
              setIsSectionNavigationVisible(false)
              return
            }

            setIsSectionNavigationVisible(true)

            if (!isScrollingIntoView) {
              setActiveSection(sectionName)
            }
          } else if (isScrollingIntoView && sectionName === activeSection) {
            // Reset the user scroll state when the target section fully leaves the view
            setTimeout(() => {
              setIsScrollingIntoView(false)
            }, 500)
          }
        })
      },
      { rootMargin: '-50% 0px -50% 0px', threshold: 0 }, // Ensure the section is fully in view
    )

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current)
      }
    })

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      })
    }
  }, [sectionRefs, isScrollingIntoView, activeSection])

  const handleSectionClick = (sectionName: string) => {
    if (isScrollingIntoView) {
      setIsScrollingIntoView(false)
      return
    }

    setIsScrollingIntoView(true) // Indicate that this scroll is user-initiated
    setActiveSection(sectionName)

    sectionRefs[sectionName]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const pageTitle = `${PAGE_TITLE_PREFIX} Home`
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <div className="z-[100] relative">
        <Layout navigation={navigation}>
          <SectionNavigation
            activeSection={activeSection}
            handleSectionClick={handleSectionClick}
            isVisible={isSectionNavigationVisible}
          />
          <div ref={HeroRef}>
            <Hero content={homepage.hero} />
          </div>
          <div
            ref={FeaturesRef}
            className="scroll-mt-header md:scroll-mt-header-lg pt-28"
          >
            <Suspense fallback={null}>
              <OurNodes content={homepage.features.ourNodes} />
            </Suspense>
            <Suspense fallback={null}>
              <Deployment content={homepage.features.deployment} />
            </Suspense>
          </div>
          <div
            ref={OurServicesRef}
            className="scroll-mt-header md:scroll-mt-header-lg"
          >
            <Suspense fallback={null}>
              <OurServices content={homepage.ourServices} />
            </Suspense>
          </div>
          {/* <div
            ref={PricingRef}
            className="scroll-mt-header md:scroll-mt-header-lg"
          >
            <Suspense fallback={null}>
              <Pricing content={pricing} />
            </Suspense>
          </div> */}
          <div
            ref={HowItWorksRef}
            className="scroll-mt-header md:scroll-mt-header-lg"
          >
            <Suspense fallback={null}>
              <HowItWorks content={homepage.howItWorks} />
            </Suspense>
          </div>
          <div
            ref={GPUPowerRef}
            className="scroll-mt-header md:scroll-mt-header-lg"
          >
            <Suspense fallback={null}>
              <GPUPower content={homepage.gpuPower} />
            </Suspense>
          </div>
          <div ref={FooterRef}>
            <Suspense fallback={null}>
              <BasicSection content={homepage.launch} end="bottom top-=100%" />
            </Suspense>
          </div>
        </Layout>
      </div>
      {/* <Loader /> */}
    </>
  )
}

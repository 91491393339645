import { SVGProps } from 'react'

const CTAArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.47501 7.318L6.55701 6.4L8.14101 4.816L9.041 4.312L8.951 4.096L8.14101 4.348H0.509003V3.052H8.14101L8.951 3.304L9.041 3.088L8.14101 2.584L6.55701 1L7.47501 0.0820007L10.229 2.836V4.564L7.47501 7.318Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CTAArrow

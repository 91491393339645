import { shaderMaterial, useTexture } from '@react-three/drei'
import { extend, ReactThreeFiber, useFrame } from '@react-three/fiber'
import { useEffect, useRef, useState } from 'react'
import {
  FrontSide,
  MirroredRepeatWrapping,
  ShaderMaterial,
  Texture,
  Vector2,
} from 'three'

import useWindowDimensions from '~/components/hooks/useWindowDimensions'
import pulseFragment from '~/shaders/pulse.frag'

const PulseMaterial = shaderMaterial(
  {
    nodeTexture: null,
    amount: 0,
    time: 0,
    resolution: new Vector2(0, 0),
    scrollY: 0,
    introAlpha: 0,
  },
  `
    uniform float amount;
    uniform float time;
    uniform vec2 resolution;

    varying vec2 vUv;
    void main() {
      vUv = uv;      
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  pulseFragment,
)

extend({ PulseMaterial })

declare global {
  namespace JSX {
    interface IntrinsicElements {
      pulseMaterial: ReactThreeFiber.Object3DNode<
        ShaderMaterial,
        typeof PulseMaterial
      > & {
        nodeTexture: Texture
        resolution: Vector2
        scrollY: number
      }
    }
  }
}

const LERP = 0.05

const Pulse = ({ scale, inViewport, track, ...props }) => {
  const material = useRef<ShaderMaterial>()
  const pulseTexture = useTexture('/images/node-tex.webp', (texture) => {
    texture.wrapS = pulseTexture.wrapT = MirroredRepeatWrapping
  })

  const windowDimensions = useWindowDimensions()
  const [introOpacity, setIntroOpacity] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setIntroOpacity(1)
    }, 1000)
  }, [])

  useFrame(({ clock }) => {
    if (inViewport && material.current) {
      const time = clock.getElapsedTime()
      material.current.uniforms.time.value = time
      material.current.uniforms.scrollY.value = props.scrollState.progress
      material.current.uniforms.introAlpha.value +=
        (introOpacity - material.current.uniforms.introAlpha.value) * LERP
    }
  })

  if (!pulseTexture && introOpacity === 1) return null

  return (
    <mesh scale={scale.xy.max() * 2} {...props} position={[0, 0, -3]}>
      <planeGeometry args={[1, 1, 124, 124]} />
      <pulseMaterial
        ref={material}
        key={PulseMaterial.key}
        side={FrontSide}
        nodeTexture={pulseTexture}
        scrollY={0}
        resolution={
          new Vector2(windowDimensions.width, windowDimensions.height)
        }
        transparent
      />
    </mesh>
  )
}

export default Pulse

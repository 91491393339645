import { ScrollScene, UseCanvas } from '@14islands/r3f-scroll-rig'
import { Loader, useGLTF, useTexture } from '@react-three/drei'
import { useFrame, useLoader, useThree } from '@react-three/fiber'
import PortableText from '@sanity/block-content-to-react'
import { easeQuadInOut } from 'd3-ease'
import { motion } from 'framer-motion-3d'
import Image from 'next/image'
import { Suspense, useEffect, useRef, useState } from 'react'
import {
  DoubleSide,
  Group,
  Mesh,
  MeshPhysicalMaterial,
  PointLight,
  RepeatWrapping,
} from 'three'
import { type GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import Button from '~/components/Button'
import CTA from '~/components/CTA'
import { Homepage } from '~/lib/sanity.queries'

import useMediaQuery from '../hooks/useMediaQuery'
import { Parallax } from '../Parallax'
import Pulse from './Pulse'

useGLTF.preload('/models/gpu-2.glb')
useTexture.preload('/images/node-tex.webp')
useTexture.preload('/images/brush-image.webp')

const Hero = ({ content }: { content: Homepage['hero'] }) => {
  const modelRef = useRef()
  const containerRef = useRef()
  const [isLoaded, setIsLoaded] = useState(false)
  const [ctaIsHovered, setCtaIsHovered] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1024px)')

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <div
      ref={containerRef}
      className="min-h-screen flex flex-col items-center justify-center p-0 px-4 pt-header-lg overflow-hidden relative mb-20 md:mb-60"
    >
      <div className="absolute w-full h-screen top-0 overflow-hidden">
        <div className="relative w-full h-full">
          <div className="-z-1 pointer-events-none absolute top-0 h-1/2 w-px bg-gradient-to-b from-grey-100 to-transparent flex justify-center left-1/2 translate -transform-x-1/2"></div>
          <div className="-z-1 pointer-events-none absolute top-0 h-[75vh] w-px bg-gradient-to-b from-grey-100 to-transparent flex justify-center left-1/3 translate -transform-x-1/2 rotate-[23deg] origin-top"></div>
          <div className="-z-1 pointer-events-none absolute top-0 h-[75vh] w-px bg-gradient-to-b from-grey-100 to-transparent flex justify-center left-2/3 translate -transform-x-1/2 -rotate-[23deg] origin-top"></div>
        </div>
      </div>
      <BackgroundColorBlur></BackgroundColorBlur>
      <div className="flex flex-col items-center justify-center z-10 w-full mt-4 md:mt-10 text-center">
        <Parallax
          trigger={containerRef}
          speed={-2}
          start="center center"
          end="bottom top"
          className="text-center w-full relative"
        >
          <div className="bg-gradient-blur-black aspect-square"></div>
          {content?.title && (
            <h1
              className={`h1 max-w-sm md:max-w-5xl md:px-10 text-gradient m-auto text-pretty
              ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8 md:translate-y-10'}
              transition duration-1000 ease-expo-out`}
            >
              <PortableText blocks={content.title} />
            </h1>
          )}
          {content?.body && (
            <div
              className={`mx-auto w-full max-w-sm md:max-w-full px-12 md:px-0 mt-3 md:mt-8 body-large text-gradient-grey md:text-grey text-pretty
              ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8 md:translate-y-10'}
              transition duration-1000 delay-200 ease-expo-out`}
            >
              <PortableText blocks={content.body} />
            </div>
          )}
          {(content?.primaryCTA || content?.secondaryCTA) && (
            <div
              className={`flex items-center justify-center mt-8 md:mt-12 flex-wrap gap-8 transform transition-transform duration-500 ${ctaIsHovered ? 'md:translate-x-6' : ''}`}
            >
              {content?.primaryCTA && (
                <Button
                  href={content.primaryCTA.url}
                  asLink
                  pin="right"
                  className={`${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8 md:translate-y-10'}
                    transition duration-1000 delay-400 ease-expo-out
                  `}
                  onMouseEnter={() => setCtaIsHovered(true)}
                  onMouseLeave={() => setCtaIsHovered(false)}
                >
                  {content.primaryCTA.text}
                </Button>
              )}
              {content?.secondaryCTA && (
                <CTA
                  href={content.secondaryCTA.url}
                  asLink
                  className={`${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8 md:translate-y-10'}
              transition duration-1000 delay-500 ease-expo-out
            `}
                >
                  {content.secondaryCTA.text}
                </CTA>
              )}
            </div>
          )}
        </Parallax>
      </div>
      <Suspense fallback={null}>
        {isMobile ? (
          <div className="right-0 bottom-0 flex items-end justify-end w-full -mr-16 md:-mr-16">
            <div className="w-full md:w-2/3">
              <Image
                src="/images/mobile-model.webp"
                alt="Hero Image"
                width="785"
                height="580"
                className="mt-10"
              />
            </div>
          </div>
        ) : (
          <div
            ref={modelRef}
            className="h-1/2 xl:h-50vh min-h-96 md:min-h-4xl w-full md:mt-12 translate translate-x-1/2 md:translate-x-0 max-w-1920"
          >
            {!isMobile && <BlurShapes></BlurShapes>}
            <UseCanvas>
              <ScrollScene track={modelRef}>
                {(props) => <Model {...props} />}
              </ScrollScene>
            </UseCanvas>
          </div>
        )}
        <UseCanvas>
          <ScrollScene track={containerRef}>
            {(props) => <Pulse {...props} />}
          </ScrollScene>
        </UseCanvas>
      </Suspense>
    </div>
  )
}

type GPUModel = GLTF & {
  nodes: {
    Nvidia_Geforce_RTX_4090_003_1: Mesh
    Nvidia_Geforce_RTX_4090_003_2: Mesh
    Nvidia_Geforce_RTX_4090_003_3: Mesh
    Nvidia_Geforce_RTX_4090_003_4: Mesh
    Nvidia_Geforce_RTX_4090_003_5: Mesh
  }
}

function Model({ scale, inViewport, scrollState, track, ...props }) {
  const { nodes } = useGLTF('/models/gpu-2.glb') as unknown as GPUModel
  const modelRef = useRef<Group>()
  const baseMaterial = useRef<MeshPhysicalMaterial>()
  const chromeMaterial = useRef<MeshPhysicalMaterial>()
  const light = useRef<PointLight>()
  const edgeLight = useRef<PointLight>()
  const edgeLight2 = useRef<PointLight>()
  const edgeLight3 = useRef<PointLight>()
  const { pointer, viewport } = useThree()
  const pulseTexture = useTexture('/images/node-tex.webp')
  const brushTexture = useTexture('/images/brush-image.webp')

  // Adjust texture repeat properties if necessary
  pulseTexture.wrapS = pulseTexture.wrapT = RepeatWrapping
  pulseTexture.repeat.set(2, 2)

  const smoothPointer = useRef({ x: 0, y: 0 })
  const lerpFactor = 0.1

  useFrame((state, delta) => {
    if (inViewport) {
      smoothPointer.current.x +=
        (pointer.x - smoothPointer.current.x) * lerpFactor
      smoothPointer.current.y +=
        (pointer.y - smoothPointer.current.y) * lerpFactor

      modelRef.current.rotation.y =
        3.6 - 0 * Math.PI * 0.2 + smoothPointer.current.x * 0.05
      modelRef.current.rotation.z = -0.05 + 0 * 10 * Math.PI * 0.1
      modelRef.current.rotation.x =
        Math.PI / 1.2 + smoothPointer.current.y * 0.05
      modelRef.current.position.z = 0 * viewport.height * 10
      light.current.position.x =
        (smoothPointer.current.x - 1.0) * (viewport.width * 0.5)
      light.current.position.y =
        (smoothPointer.current.y + 1.0) * (viewport.height * 0.5) - 2.0

      const elapsedTime = state.clock.getElapsedTime()
      const period = 2 // Match the shader's cycle period of 2 seconds
      const normalizedTime = (elapsedTime % period) / period // Normalize time to range [0, 1]

      // Adjust the phase so that the peak intensity occurs at 50% of the period
      const peakTime = 0.5
      const easeTime = Math.abs((normalizedTime - peakTime) * 2) // Scale and mirror around the peak
      const easedIntensity = easeQuadInOut(easeTime) // Apply easing function

      let intensity = 0.5 + 100 * (1 - easedIntensity) // Apply base intensity plus eased value

      edgeLight.current.intensity = intensity

      edgeLight.current.intensity = intensity
      edgeLight2.current.intensity = intensity
      edgeLight3.current.intensity = intensity
    }
  })

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <>
      <meshPhysicalMaterial
        color="#040404"
        reflectivity={1}
        metalness={0.2}
        clearcoat={20}
        clearcoatRoughness={0.3}
        roughness={0.5}
        side={DoubleSide}
        ref={baseMaterial}
        emissive="#040404"
      />
      <meshPhysicalMaterial
        color={'grey'}
        metalness={0.2}
        roughness={0}
        reflectivity={4}
        clearcoat={40}
        clearcoatRoughness={0.4}
        side={DoubleSide}
        ref={chromeMaterial}
        map={brushTexture}
      />
      <pointLight
        castShadow
        ref={light}
        color="#4A8463"
        position={[0, 0, scale.xy.min()]}
        intensity={40}
        decay={1}
        distance={scale.xy.min() * 4}
      />
      <pointLight
        castShadow
        ref={edgeLight}
        color="#4A8463"
        position={[
          scale.xy.min() * 1.7,
          -scale.xy.min() * 1.5,
          scale.xy.min() * 1.4,
        ]}
        scale={[1, 3, 1]}
        intensity={0}
        decay={1}
        distance={scale.xy.min() * 4}
      ></pointLight>
      <pointLight
        castShadow
        ref={edgeLight2}
        color="#4A8463"
        position={[
          scale.xy.min() * 0.4,
          scale.xy.min() * 0.9,
          scale.xy.min() * 1.9,
        ]}
        scale={[1, 3, 1]}
        intensity={0}
        decay={1}
        distance={scale.xy.min() * 4}
      />
      <pointLight
        castShadow
        ref={edgeLight3}
        color="#4A8463"
        position={[
          scale.xy.min() * 0,
          scale.xy.min() * 0.9,
          -scale.xy.min() * 1.5,
        ]}
        scale={[1, 3, 1]}
        intensity={1}
        decay={1}
        distance={scale.xy.min() * 4}
      />
      {baseMaterial.current && chromeMaterial.current && isLoaded && (
        <group scale={scale.xy.min() * 3.2} {...props} ref={modelRef}>
          <motion.group
            initial={{ y: 0.5, opacity: 0, rotateX: 0.3, rotateY: -1 }}
            animate={{ y: 0, opacity: 0, rotateX: 0, rotateY: 0 }}
            transition={{ type: 'spring', bounce: 0.2, duration: 2 }}
          >
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Nvidia_Geforce_RTX_4090_003_1.geometry}
              material={baseMaterial.current}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Nvidia_Geforce_RTX_4090_003_2.geometry}
              material={baseMaterial.current}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Nvidia_Geforce_RTX_4090_003_3.geometry}
              material={baseMaterial.current}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Nvidia_Geforce_RTX_4090_003_4.geometry}
              material={baseMaterial.current}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Nvidia_Geforce_RTX_4090_003_5.geometry}
              material={chromeMaterial.current}
            />
          </motion.group>
        </group>
      )}
    </>
  )
}

const BlurShapes = () => (
  <div className="absolute w-full h-[200%] bottom-0 left-0 overflow-hidden pointer-events-none z-10">
    <div className="hero-gradient aspect-square h-full absolute -bottom-1/4 -left-[10%]"></div>
    <div className="hero-gradient aspect-square h-full absolute -bottom-1/4 -right-[10%]"></div>
  </div>
)

const BackgroundColorBlur = () => (
  <div className="absolute w-[80vw] h-[80vh] overflow-hidden pointer-events-none z-10 left-[10vw] top-0 transform">
    <svg
      width="1891"
      height="1587"
      className="w-full h-full"
      viewBox="0 0 1891 1587"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_1459_13)">
        <path
          d="M500.172 1086.14L771.743 500.139H1123.98L1390.17 1086.14H500.172Z"
          fill="url(#paint0_linear_1459_13)"
          fillOpacity="0.7"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1459_13"
          x="0.171875"
          y="0.138672"
          width="1890"
          height="1586"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="250"
            result="effect1_foregroundBlur_1459_13"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1459_13"
          x1="945.172"
          y1="500.139"
          x2="945.172"
          y2="1086.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#090909" />
          <stop offset="1" stopColor="#002D40" />
        </linearGradient>
      </defs>
    </svg>
  </div>
)

export default Hero

import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'

import useMediaQuery from './hooks/useMediaQuery'
import useWindowDimensions from './hooks/useWindowDimensions'

export function Parallax({
  className,
  children,
  speed = 1,
  id = 'parallax',
  start = 'top bottom', // this means the animation will start when the top of the trigger element reaches the bottom of the viewport
  end = 'bottom top', // this means the animation will end when the bottom of the trigger element reaches the top of the viewport
  trigger: triggerEl,
}: {
  className?: string
  children: React.ReactNode
  speed?: number
  id?: string
  start?: string
  end?: string
  trigger?: any
}) {
  const trigger = useRef() // this is the element that will trigger the animation
  const target = useRef() // this is the element that will be animated
  const timeline = useRef<gsap.core.Timeline>() // this is the timeline of the animation that will be created by gsap
  const { width: windowWidth } = useWindowDimensions()
  const isMobile = useMediaQuery('(max-width: 1024px)')

  useEffect(() => {
    if (isMobile) {
      return () => {
        timeline?.current?.kill()
      }
    } // Exit the effect if on a mobile device

    const y = windowWidth * speed * 0.1
    const setY = gsap.quickSetter(target.current, 'y', 'px')

    timeline.current = gsap.timeline({
      scrollTrigger: {
        id: id,
        trigger: triggerEl?.current ?? trigger.current,
        scrub: true,
        start,
        end,
        onUpdate: (e) => {
          setY(e.progress * y)
        },
      },
    })

    return () => {
      timeline?.current?.kill()
    }
  }, [children, end, id, isMobile, speed, start, triggerEl, windowWidth])

  if (isMobile) {
    return <>{children}</>
  }

  return (
    <div ref={trigger}>
      <div ref={target} className={className}>
        {children}
      </div>
    </div>
  )
}
